import React from 'react'
import { Link, graphql } from 'gatsby'
import debounce from 'lodash/debounce'
import Helmet from 'react-helmet'

import Bio from '../components/bio'
import Layout from '../components/layout'
import { rhythm } from '../utils/typography'

// This would normally be in a Redux store or some other global data store.
if (typeof window !== `undefined`) {
  window.itemsToShow = 12
}

function Product(props) {
  const { title, price, image, amazonid, params } = props
  const productUrl = `https://amzn.com/${amazonid}/?tag=inbendco-20${params || ''}`
  const styles = {
    container: {
      borderRadius: '2px',
      display: 'inline-block',
      margin: '16px',
      position: 'relative',
    },
    link: {
      borderTopLeftRadius: '2px',
      borderTopRightRadius: '2px',
    },
    product: {
      backgroundColor: '#fff',
      borderRadius: '2px',
      boxShadow: '0px 3px 8px -4px rgba(0,0,0,0.15)',
      color: '#000',
      height: '100%',
      textAlign: 'center',
      transitionDuration: '0.4s',
      width: '100%',
    },
    price: {
      // backgroundColor: '#2fdab8',
      backgroundColor: '#FF7043',
      color: 'white',
      fontSize: '1.25em',
      fontWeight: '400',
      marginLeft: 'auto',
      padding: '.4em 0',
      width: '80px',
    },
    imageWrap: {
      backgroundColor: '#fff',
      border: '1px solid #F2F2F2',
      borderColor: 'transparent',
      borderColor: '#fff',
      borderTopLeftRadius: '2px',
      borderTopRightRadius: '2px',
      margin: '0 20%',
      overflow: 'hidden',
      paddingTop: '80%',
      position: 'relative',
      width: '60%',
    },
    image: {
      backgroundImage: `url(https://images-na.ssl-images-amazon.com/images/I/${image})`,
      backgroundPosition: 'center',
      backgroundOrigin: 'content-box',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      borderTopLeftRadius: '2px',
      borderTopRightRadius: '2px',
      padding: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    title: {
      fontSize: '15px',
      fontWeight: 'normal',
      marginBottom: 0,
      overflow: 'hidden',
      padding: '24px 32px 24px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }

  return (
    <div className="product-container" style={styles.container}>
      <a target="_blank" href={productUrl} style={styles.link}>
        <div className="product" style={styles.product}>
          <div className="product-price" style={styles.price}>
            ${Math.round(price)}
          </div>
          <div className="product-img-wrap" style={styles.imageWrap}>
            <div className="product-img" style={styles.image}>
            </div>
          </div>
          <div className="product-title" style={styles.title}>
            {title}
          </div>
        </div>
      </a>
    </div>
  )
}

class StoreIndex extends React.Component {
  constructor(props) {
    super(props)

    let itemsToShow = 12
    if (typeof window !== `undefined`) {
      // itemsToShow = window.itemsToShow
    }

    this.state = {
      showingMore: itemsToShow > 12,
      itemsToShow,
      filter: '',
      filterInput: '',
    }

    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.setFilter = debounce(this.setFilter, 400)
  }

  update() {
    console.warn('update')
    const distanceToBottom =
      document.documentElement.offsetHeight -
      (window.scrollY + window.innerHeight)
    if (this.state.showingMore && distanceToBottom < 100) {
      this.setState({ itemsToShow: this.state.itemsToShow + 12 })
    }
    this.ticking = false
  }

  // update = () => debounce(() => {
  //   console.warn('update')
  //   const distanceToBottom =
  //     document.documentElement.offsetHeight -
  //     (window.scrollY + window.innerHeight)
  //   if (this.state.showingMore && distanceToBottom < 100) {
  //     this.setState({ itemsToShow: this.state.itemsToShow + 12 })
  //   }
  //   this.ticking = false
  // }, 300)

  handleScroll = () => {
    if (!this.ticking) {
      this.ticking = true
      requestAnimationFrame(() => this.update())
    }
  }

  componentDidMount() {
    window.addEventListener(`scroll`, this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.handleScroll)
    window.itemsToShow = this.state.itemsToShow
  }

  handleFilterChange(event) {
    this.setState({ filterInput: event.target.value })
    this.setFilter(event.target.value)
  }

  setFilter(filter) {
    this.setState({ filter, })
  }

  // createCollection() {
  //   const collection = []
  //   for(var i = 0; i < 24; i++) {
  //     collection.push(`Item ${i + 1}`)
  //   }
  //   return collection
  // }

  render() {
    console.warn('render - StoreIndex')
    const siteTitle = this.props.data.site.siteMetadata.title
    const collection = this.props.data.allGoogleSheetSheet1Row.edges
      .map(item => item.node)
      .filter(item => item.visible)
      .filter(item => item.title.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1)
    console.log('collection:', collection)

    return (
      <Layout location={this.props.location} title={siteTitle} contentBackground="#f2f2f2">
        <Helmet title={siteTitle} />
        <input
          type="text"
          placeholder="Search"
          value={this.state.filterInput}
          onChange={this.handleFilterChange}
          style={{
            border: '1px solid #efefef',
            borderRadius: '24px',
            padding: '0.5em 1.5em',
            outline: 0,
          }}
        />
        <div
          className="products"
          style={{
            margin: '0 -16px',
          }}
        >
          {collection.slice(0, this.state.itemsToShow).map((item, index) => <Product key={index} {...item} />)}
        </div>
        {!this.state.showingMore && collection.length > this.state.itemsToShow && (
          <button
            className="load-more-button"
            onClick={() => {
              this.setState({
                itemsToShow: this.state.itemsToShow + 12,
                showingMore: true,
              })
            }}
            style={{
              backgroundColor: '#e3e3e3',
              border: 'none',
              color: '#999',
              cursor: 'pointer',
              outline: 'none',
              padding: '32px 0',
              transition: 'all 0.2s ease-in-out',
              width: '100%',
            }}
          >
            Load more
          </button>
        )}
      </Layout>
    )
  }
}

export default StoreIndex

export const pageQuery = graphql`
  query StoreQuery {
    site {
      siteMetadata {
        title
      }
    }
    allGoogleSheetSheet1Row(limit: 1000) {
      edges {
        node {
          visible
          title
          amazonid
          price
          categories
          image
          params
        }
      }
    }
  }
`


// import React from 'react'
// import { Link, graphql } from 'gatsby'
// import Helmet from 'react-helmet'

// import Layout from '../components/layout'
// import { rhythm } from '../utils/typography'

// function SiteIndex(props) {
//   const siteTitle = props.data.site.siteMetadata.title
//   return (
//     <Layout location={props.location} title={siteTitle}>
//       <Helmet title={siteTitle} />
//       <ul>
//         <li><Link to={'/'}>Home</Link></li>
//         <li><Link to={'/gear/'}>Gear</Link></li>
//         <li><Link to={'/traffic/'}>Traffic</Link></li>
//         <li><Link to={'/explore/'}>Explore</Link></li>
//         <li><Link to={'/events/'}>Events</Link></li>
//         <li><Link to={'/food-and-drink/'}>Food &amp; Drink</Link></li>
//         <li><Link to={'/blog/'}>Blog</Link></li>
//         <li><Link to={'/online/'}>Online</Link></li>
//         <li><Link to={'/tech/'}>Tech</Link></li>
//         <li><Link to={'/about/'}>About</Link></li>
//       </ul>
//     </Layout>
//   )
// }

// export default SiteIndex

// export const pageQuery = graphql`
//   query IndexQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `
